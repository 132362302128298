body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: rgba(240, 240, 240, 0.6);
}

.main-content {
    margin-top: 60px;
    text-align: center;
}

.background-image {
    background-image: url("../../assets/images/beach.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 100%; /* Set width to 100% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}

.company-title {
    font-size: 2.5em;
    margin: 0;
}

.company-subtitle {
    font-size: 1.5em;
    margin: 0;
}

.logo-over-background {
    width: 20vw;
    max-width: 300px;
    margin: 0 auto;
    position: relative;
    top: -10%;
}

/* Apply different styles for smaller screens */
@media screen and (max-width: 768px) {
    .logo-over-background {
        width: 30vw; /* Increase the percentage for smaller screens */
    }
}

.request-button {
    visibility: hidden;
}

.request-button button {
    font-size: 1.2em; /* Increase the font size */
    padding: 12px 24px; /* Increase padding around the text */
}

/* Hide request button on larger screens */
@media screen and (max-width: 910px) {
    .request-button {
        visibility: visible;
        padding: 20px;
        height: 40px
    }
}