.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    background-color: #f8f9fa;
    color: #555;
    font-family: 'Montserrat', sans-serif;
    margin-top: 60px;
}

.main-content h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: 700;
}

.headshot-container {
    position: relative;
    width: 100%;
    max-width: 400px;
}

/* Initial state of the headshot */
.headshot {
    width: 100%;
    margin: 2rem 0;
    margin-top: 5px;
    opacity: 0;
    /* Make it initially transparent */
    transform: scale(0.9);
    /* Start a bit smaller */
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.3),
        0 4px 8px rgba(0, 0, 0, 0.08),
        0 8px 16px rgba(0, 0, 0, 0.06),
        0 16px 32px rgba(0, 0, 0, 0.04);
    animation: fadeInScaleUp 1.5s forwards 0.2s cubic-bezier(0.2, 0.8, 0.2, 1);
    /* Apply the animation */
}

/* Keyframes for the animation */
@keyframes fadeInScaleUp {
    to {
        opacity: 1;
        /* End at full opacity */
        transform: scale(1);
        /* End at normal scale */
    }
}



.description {
    max-width: 800px;
    line-height: 1.5;
    font-size: 1.1rem;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.description p {
    margin: 1.2rem 0;
}

.message-from-ionatan {
    max-width: 800px;
    background-color: #eef2f7;
    padding: 2rem;
    padding-bottom: 0;
    border-radius: 8px;
    text-align: center;
    font-size: 1.1rem;
    margin: 2rem 0;
}

.message-from-ionatan h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
    font-weight: 700;
}

.message-from-ionatan blockquote {
    font-style: italic;
}

.our-providers-title {
    font-size: calc(2rem + 0.5vw);
    /* Base size is 2rem and will scale with viewport width */
    white-space: nowrap;
    /* Ensures title remains on a single line */
    overflow: hidden;
    /* To hide overflowed content */
    text-overflow: ellipsis;
    /* Ellipsis to show content is clipped */
    margin-bottom: 15px;
    margin-top: 20px;
    padding: 0 1rem;
    /* Adding side padding to ensure text never hits the sides */
}