/* Resources.css */
.resources-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    color: #555;
    font-family: 'Montserrat', sans-serif;
    padding: 20px;
    padding-bottom: 0;
    width: 100%;
    margin: 0 auto; /* Center the content */
    margin-top: 60px;
    padding-left: 0;
    min-height: calc(100vh - 80px);;
}

.resources-content h1 {
    font-size: 2rem; /* Slightly smaller base font size */
    color: #333;
    margin-bottom: 1rem;
    font-weight: 700;
}

/* Using media query to adjust title size on smaller screens */
@media screen and (max-width: 768px) {
    .resources-content h1 {
        font-size: 1.5rem;
    }
}

.resources-description {
    max-width: 800px;
    line-height: 1.5;

    text-align: center;
    font-size: calc(1rem + 0.2vw);
    padding: 0 1rem; /* Side padding to ensure text never hits the sides */
}

.resources-link {
    max-width: 800px;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    font-size: 1.1rem;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
}

.resources-link img {
    max-width: 300px; /* Larger max-width for the FullScript image */
    max-height: 300px; /* Larger max-height for the FullScript image */
}
