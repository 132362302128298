.top-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    height: 60px;
    line-height: 40px;
    z-index: 100;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
}

.menu-items {
    display: flex;
    flex-direction: row;
    padding-right: 15px;
}

.logo {
    display: block;
    height: 50px;
    margin-right: auto;
}

/* Hamburger menu icon styles */
.hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 30px;
}

.hamburger-bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
}

/* Hide regular menu items and show hamburger icon on smaller screens */
@media screen and (max-width: 910px) {
    .menu-items {
        display: none;
    }
    .hamburger-menu {
        display: flex;
    }
}

/* Mobile menu styles */
.mobile-menu {
    position: absolute;
    top: 60px;
    right: 15px;
    background-color: white;
    border: 1px solid #ccc;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

/* Show mobile menu when it's open */
.mobile-menu.open {
    display: flex;
}